import React from "react";
import { useLocation } from "react-router-dom";
import { useTypewriter } from "react-simple-typewriter";
import boxingGlove from "./images/about/boxing-glove.png";
import minky from "./images/about/minky.png";
import plane from "./images/about/airplane.webp";
import controller from "./images/about/controller.png";
import times from "./images/about/time-magazine.png";
import snt from "./images/about/Sigma-Nu-Tau-Logo.png";

const About = () => {
  const location = useLocation();
  const [text] = useTypewriter({
    words: [
      "a Developer",
      "a Designer",
      "a Creator",
      "a Learner",
      "a Salesman",
    ],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });

  const interestsAndHobbies = [
    {
      image: minky,
      title: "Chilling with Minky",
      text: "I am the owner of arguably the best dog in the world...Minky. I love taking her outside on a walk and give her treats.",
    },
    {
      image: boxingGlove,
      title: "Boxing",
      text: "Learned to box at 19 to build discipline and blow off steam in a calm and collected manner.",
    },
    {
      image: plane,
      title: "Travel",
      text: "Traveling is my favorite way to learn about different cultures, try different foods, and take a trip away from reality.",
    },
    {
      image: controller,
      title: "Gaming",
      text: "Exploring and hanging out with friends online in a virtual reality is my favorite way to take a break from life.",
    },
  ];

  const awards = [
    {
      icon: times,
      title: "2006 Time's Person of the Year",
      text: "Awarded 2006 Time's Person of the Year for contributing to user-generated content.",
    },
    {
      icon: snt,
      title: "Sigma Nu Tau",
      text: "Joined Sigma Nu Tau's Entrepreneurship honor society during my undergraduate.",
    },
  ];

  return (
    <article
      className={`about ${location.pathname === "/" ? "active" : ""}`}
      data-page="about"
    >
      <header>
        <h2 className="h2 article-title">About me</h2>
      </header>

      <section className="about-text">
        <p style={{ fontSize: "25px" }}>Andy Kim {text}_</p>
        <hr />
        <p style={{ marginTop: "15px" }}>
          A developer fueled by the drive of a salesman. Born and bred in New
          York, my journey into the world of business began through sales and
          education. Driven by a desire for self-improvement, I undertook the
          challenge of gaining a deeper understanding of technology, aiming to
          enhance my professional growth and career prospects.
        </p>
      </section>

      <section className="testimonials">
        <h3 className="h3 testimonials-title">Interests & Hobbies</h3>
        <ul className="testimonials-list has-scrollbar">
          {interestsAndHobbies.map((item, index) => (
            <li className="testimonials-item" key={index}>
              <div className="content-card" data-testimonials-item>
                <figure className="testimonials-avatar-box">
                  <img
                    src={item.image}
                    alt={item.title}
                    width="60"
                    data-testimonials-avatar
                  />
                </figure>
                <h4
                  className="h4 testimonials-item-title"
                  data-testimonials-title
                >
                  {item.title}
                </h4>
                <div className="testimonials-text" data-testimonials-text>
                  <p>{item.text}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <section className="service">
        <h3 className="h3 service-title">Awards</h3>
        <ul className="service-list">
          {awards.map((award, index) => (
            <li className="service-item" key={index}>
              <div className="service-icon-box">
                <img src={award.icon} alt={`Award ${index + 1}`} width="40" />
              </div>
              <div className="service-content-box">
                <h4 className="h4 service-item-title">{award.title}</h4>
                <p className="service-item-text">{award.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </article>
  );
};

export default About;
