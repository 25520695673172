import React, { useState } from "react";
import myImage from "./images/my-avatar.png";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`} data-sidebar>
      <div className="sidebar-info">
        <figure className="avatar-box">
          <img src={myImage} alt="Andy Kim" width="80" />
        </figure>

        <div className="info-content">
          <h1 className="name" title="Andy Kim">
            Andy Kim
          </h1>
          <p className="title">Engineer</p>
        </div>

        <button
          className="info_more-btn"
          data-sidebar-btn
          onClick={handleSidebarToggle}
        >
          <span>Show Contacts</span>
          <ion-icon
            name={`chevron-${isSidebarOpen ? "up" : "down"}`}
          ></ion-icon>
        </button>
      </div>
      <div className="sidebar-info_more">
        <div className="separator"></div>

        <ul className="contacts-list">
          <li className="contact-item">
            <div className="icon-box">
              <ion-icon name="mail-outline"></ion-icon>
            </div>
            <div className="contact-info">
              <p className="contact-title">Email</p>
              <p className="contact-link">andy.s.kim95@gmail.com</p>
            </div>
          </li>

          <li className="contact-item">
            <div className="icon-box">
              <ion-icon name="phone-portrait-outline"></ion-icon>
            </div>
            <div className="contact-info">
              <p className="contact-title">Phone</p>
              <p className="contact-link">+1(516)864-3779</p>
            </div>
          </li>

          <li className="contact-item">
            <div className="icon-box">
              <ion-icon name="location-outline"></ion-icon>
            </div>
            <div className="contact-info">
              <p className="contact-title">Location</p>
              <address>New York, NY</address>
            </div>
          </li>
        </ul>

        <div className="separator"></div>

        <ul className="social-list">
          <li className="social-item">
            <a
              href="https://www.linkedin.com/in/andy-s-kim/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <ion-icon name="logo-linkedin"></ion-icon>
            </a>
          </li>

          <li className="social-item">
            <a
              href="https://github.com/andykeem-dacreator"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <ion-icon name="logo-github"></ion-icon>
            </a>
          </li>

          {/* <li className="social-item">
            <p className="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </p>
          </li> */}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
