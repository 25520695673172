// import React from "react";

// const Navbar = () => {
//   return (
//     <nav className="navbar">
//       <ul className="navbar-list">
//         <li className="navbar-item">
//           <button className="navbar-link active" data-nav-link>
//             About
//           </button>
//         </li>
//         <li className="navbar-item">
//           <button className="navbar-link" data-nav-link>
//             Resume
//           </button>
//         </li>
//         <li className="navbar-item">
//           <button className="navbar-link" data-nav-link>
//             Portfolio
//           </button>
//         </li>
//         <li className="navbar-item">
//           <button className="navbar-link" data-nav-link>
//             Blog
//           </button>
//         </li>
//         <li className="navbar-item">
//           <button className="navbar-link" data-nav-link>
//             Contact
//           </button>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  // console.log("Current Path:", location.pathname);

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li
          className={`navbar-item ${location.pathname === "/" ? "active" : ""}`}
        >
          <Link to="/" className="navbar-link">
            About
          </Link>
        </li>
        <li
          className={`navbar-item ${
            location.pathname === "/resume" ? "active" : ""
          }`}
        >
          <Link to="/resume" className="navbar-link">
            Resume
          </Link>
        </li>
        <li
          className={`navbar-item ${
            location.pathname === "/projects" ? "active" : ""
          }`}
        >
          <Link to="/projects" className="navbar-link">
            Projects
          </Link>
        </li>

        <li
          className={`navbar-item ${
            location.pathname === "/contact" ? "active" : ""
          }`}
        >
          <Link to="/contact" className="navbar-link">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
