// import React, { useState } from "react";
// import emailjs from "emailjs-com";
// import { useLocation } from "react-router-dom";

// const Contact = () => {
//   const location = useLocation();

//   const [formData, setFormData] = useState({
//     fullname: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Add your Email.js configuration
//     const emailJsParams = {
//       service_id: "YOUR_SERVICE_ID",
//       template_id: "YOUR_TEMPLATE_ID",
//       user_id: "YOUR_USER_ID",
//       template_params: {
//         fullname: formData.fullname,
//         email: formData.email,
//         message: formData.message,
//       },
//     };

//     // Send the email
//     emailjs.sendForm(
//       emailJsParams.service_id,
//       emailJsParams.template_id,
//       emailJsParams.template_params,
//       emailJsParams.user_id
//     );

//     // Clear the form after submission
//     setFormData({
//       fullname: "",
//       email: "",
//       message: "",
//     });
//   };

//   return (
//     <article
//       className={`contact ${location.pathname === "/contact" ? "active" : ""}`}
//       data-page="contact"
//     >
//       <header>
//         <h2 className="h2 article-title">Contact</h2>
//       </header>

//       <section className="mapbox" data-mapbox>
//         <figure>
//           <iframe
//             title="Google Maps"
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63432.57438824277!2d3.380540514233446!3d6.453569645603896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf4cc9b07cf55%3A0xc4ae10b395418b9b!2sLagos%20Island!5e0!3m2!1sen!2sng!4v1683226870013!5m2!1sen!2sng"
//             width="400"
//             height="300"
//             loading="lazy"
//           ></iframe>
//         </figure>
//       </section>

//       <section className="contact-form">
//         <h3 className="h3 form-title">Contact Form</h3>

//         <form onSubmit={handleSubmit} className="form" data-form>
//           <div className="input-wrapper">
//             <input
//               type="text"
//               name="fullname"
//               value={formData.fullname}
//               onChange={handleChange}
//               className="form-input"
//               placeholder="Full name"
//               required
//               data-form-input
//             />

//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="form-input"
//               placeholder="Email address"
//               required
//               data-form-input
//             />
//           </div>

//           <textarea
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             className="form-input"
//             placeholder="Your Message"
//             required
//             data-form-input
//           ></textarea>

//           <button type="submit" className="form-btn" data-form-btn>
//             <ion-icon name="paper-plane"></ion-icon>
//             <span>Send Message</span>
//           </button>
//         </form>
//       </section>
//     </article>
//   );
// };

// export default Contact;

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const location = useLocation();

  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pml7i9m", // Your service ID here
        "template_4llkr0p",
        e.target, // Use e.target as the form element
        "8hR3w-P0mebh7OeFS"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");

          // Clear form fields
          form.current.reset();

          // Set state to display message sent popup
          setIsMessageSent(true);

          // Reset the state after a delay (you can customize the delay)
          setTimeout(() => {
            setIsMessageSent(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <article
      className={`contact ${location.pathname === "/contact" ? "active" : ""}`}
      data-page="contact"
    >
      <header>
        <h2 className="h2 article-title">Contact</h2>
      </header>

      <section className="mapbox" data-mapbox>
        <figure>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387133.4428901782!2d-74.25908989999999!3d40.6974034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1683232598895!5m2!1sen!2sus"
            width="400"
            height="300"
            loading="lazy"
          ></iframe>
        </figure>
      </section>

      <section className="contact-form">
        <h3 className="h3 form-title">Contact Form</h3>

        <form onSubmit={sendEmail} ref={form} className="form" data-form>
          <div className="input-wrapper">
            <input
              type="text"
              name="fullname"
              className="form-input"
              placeholder="Full name"
              required
              data-form-input
            />

            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Email address"
              required
              data-form-input
            />
          </div>

          <textarea
            name="message"
            className="form-input"
            placeholder="Your Message"
            required
            data-form-input
          ></textarea>

          <button
            type="submit"
            className="form-btn"
            disabled={isMessageSent}
            data-form-btn
          >
            <ion-icon name="paper-plane"></ion-icon>
            <span>Send Message</span>
          </button>
        </form>

        {/* Display popup when message is sent */}
        {isMessageSent && (
          <div className="message-popup" style={{ color: "white" }}>
            <p>Message sent!</p>
          </div>
        )}
      </section>
    </article>
  );
};

export default Contact;
