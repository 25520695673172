import React from "react";
import { useLocation } from "react-router-dom";
import pokedex from "./images/pokedex.png";
import movieLibrary from "./images/movieLibrary.png";
import toDoList from "./images/to-do-list.png";
// import paksWatches from "./images/paksWatches.png";
import sideJobs from "./images/side-jobs.png";

const Projects = () => {
  const location = useLocation();

  const projects = [
    {
      title: "Side Jobs",
      category: "Personal",
      link: "https://side-jobs.onrender.com/",
      image: sideJobs,
      date: "2023",
      content:
        "A comprehensive full stack online freelance website, with Render, connecting users seeking convenience with those looking to earn extra income through side hustles.",
    },
    {
      title: "The Movie Library",
      category: "Personal",
      link: "https://the-movie-library-taupe.vercel.app/",
      image: movieLibrary,
      date: "2023",
      content:
        "Used the 'The Movie Database API' to pull most popular movies, movie posters, title and descriptions, search movies, and view trailers of movies using Youtube.",
    },
    {
      title: "Pokedex",
      category: "Personal",
      link: "https://pokedex-pink-psi.vercel.app/",
      image: pokedex,
      date: "2022",
      content:
        "One of my first projects created. A simple pokedex created using the PokeAPI. Can display the Pokemon along with their type and description all in order from 1-493.",
    },
    {
      title: "To-Do List",
      category: "Personal",
      link: "https://to-do-list-eta-sage.vercel.app/",
      image: toDoList,
      date: "2024",
      content:
        "A React To-Do List app that lets users add, complete, and delete tasks. Task data is saved locally, ensuring persistent storage even after a page refresh.",
    },
    // Add more projects posts as needed
  ];

  return (
    <article
      className={`projects ${
        location.pathname === "/projects" ? "active" : ""
      }`}
      data-page="projects"
    >
      <header>
        <h2 className="h2 article-title">Projects</h2>
      </header>
      <section className="projects-posts">
        <ul className="projects-posts-list">
          {projects.map((post, index) => (
            <li key={index} className="projects-post-item">
              <a href={post.link} target="_blank" rel="noopener noreferrer">
                <figure className="projects-banner-box">
                  <img src={post.image} alt={post.title} loading="lazy" />
                </figure>
                <div className="projects-content">
                  <div className="projects-meta">
                    <p className="projects-category">{post.category}</p>
                    <span className="dot"></span>
                    <time dateTime="2022-02-23">{post.date}</time>
                  </div>
                  <h3 className="h3 projects-item-title">{post.title}</h3>
                  <p className="projects-text">{post.content}</p>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </section>
    </article>
  );
};

export default Projects;
